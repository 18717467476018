import styled from "styled-components";

export const Container = styled.div`
  min-width: 372px;
  margin: 0px auto;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;

  * {
    z-index: 2;
  }

  > img {
    display: block;
  }

  .card {
    min-width: 280px;
    width: fit-content;
    margin: 0 auto;
    position: relative;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    z-index: 2;
  }

  .background {
    position: absolute;
    width: 120%;
    height: 375px;
    z-index: 1;
    display: none;
    top: -16px;
    left: -16px;
    background: #58595b;
  }

  @media screen and (max-width: 767px) {
    > img {
      display: none;
    }
    .background {
      display: block;
    }
    .card {
      padding: 24px;
      background: white;
      margin-bottom: 120px;
    }
  }

  & .error-message {
    color: red;
    font-size: 12px;
    margin-top: 16px;
  }

  & .input-container {
    & input {
      border-radius: 8px;
      border: 1px solid var(--color-border);
      color: var(--color-grey);
      color: #000;
      font-weight: 400;
      font-size: 16px;

      &:focus {
        outline: none;
        background: #f9f9f9;
        border-color: #f9f9f9;
      }
    }
    & span {
      margin: 0 4px;
    }
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

export const Row = styled.div`
  display: flex;
  align-items: row;
  justify-content: center;
  gap: 8px;
`;

export const NumberHolder = styled.span`
  color: #58595b;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  min-height: 24px;
`;

export const Line = styled.div`
  width: 2px;
  height: 20px;
  transform: rotate(90deg);
  border-radius: 40px;
  background: #be1e2d;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Header = styled.div`
  @media screen and (max-width: 767px) {
    width: 367px;
  }

  h1 {
    color: #58595b;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: Spartan;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  > img {
    width: 60px;
    height: 60px;
    flex-shrink: 0;
  }

  @media screen and (min-width: 767px) {
    > img {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    display: flex;
    gap: 16px;
    padding: 16px 0px;

    h1 {
      text-align: start;
      max-width: 250px;
      color: var(--White, #fff);
    }
  }
`;

export const TextSubtle = styled.span`
  color: var(--Gray-500, #a6a6a6);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const TextRegular = styled.span`
  color: #041942;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
`;

export const TextAction = styled.span`
  color: #be1e2d;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  &.disabled {
    color: #58595b;
  }

  &:hover:not(.disabled) {
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const Submit = styled.button`
  border-radius: 8px;
  background: #be1e2d;
  padding: 10px 24px;
  border: none;
  outline: none;
  color: #fff;
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
  &:hover {
    cursor: pointer;
    background-color: #db3445;
  }

  &:disabled {
    border-radius: 8px;
    background: var(--Gray-20, #ccc);
  }
`;

export const ErrorBadge = styled.div`
  display: flex;
  width: fit-content;
  padding: 3px 8px;
  align-items: center;
  justify-content: start;
  gap: 6px;

  border-radius: 4px;
  border: 1px solid var(--Red-100, #f04e53);

  color: var(--Red-100, #f04e53);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;

export const SuccessBadge = styled.div`
  display: flex;
  width: fit-content;
  padding: 3px 8px;
  align-items: center;
  justify-content: start;
  gap: 6px;

  border-radius: 4px;
  border: 1px solid var(--Red-100, #18e5ab);

  color: var(--Red-100, #18e5ab);
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Spartan;
  font-size: 12.8px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`;
