import React from "react";

const Options = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="42"
      height="30"
      viewBox="0 0 42 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5007 0.333008C18.3973 0.333008 11.834 6.89634 11.834 14.9997C11.834 23.103 18.3973 29.6663 26.5007 29.6663C34.604 29.6663 41.1673 23.103 41.1673 14.9997C41.1673 6.89634 34.604 0.333008 26.5007 0.333008ZM4.50065 14.9997C4.50065 10.618 7.06732 6.82301 10.789 5.06301C11.4123 4.76967 11.834 4.20134 11.834 3.52301V3.17467C11.834 1.92801 10.5323 1.13967 9.41398 1.67134C4.35398 3.98134 0.833984 9.07801 0.833984 14.9997C0.833984 20.9213 4.35398 26.018 9.41398 28.328C10.5323 28.8413 11.834 28.0713 11.834 26.8247V26.4947C11.834 25.8163 11.4123 25.2297 10.789 24.9363C7.06732 23.1763 4.50065 19.3813 4.50065 14.9997Z"
        fill={color}
      />
    </svg>
  );
};

export default Options;
