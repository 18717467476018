import React from "react";

export default function BurgerMenu({
  size = "2.4rem",
  fill = "none",
  color = "#1E84BE",
}) {
  return (
    <svg
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666687 0.666667C0.666687 0.298477 0.965164 0 1.33335 0H10.6667C11.0349 0 11.3334 0.298477 11.3334 0.666667C11.3334 1.03486 11.0349 1.33333 10.6667 1.33333H1.33335C0.965164 1.33333 0.666687 1.03486 0.666687 0.666667ZM0.666687 4C0.666687 3.63181 0.965164 3.33333 1.33335 3.33333H10.6667C11.0349 3.33333 11.3334 3.63181 11.3334 4C11.3334 4.36819 11.0349 4.66667 10.6667 4.66667H1.33335C0.965164 4.66667 0.666687 4.36819 0.666687 4ZM0.666687 7.33333C0.666687 6.96514 0.965164 6.66667 1.33335 6.66667H10.6667C11.0349 6.66667 11.3334 6.96514 11.3334 7.33333C11.3334 7.70152 11.0349 8 10.6667 8H1.33335C0.965164 8 0.666687 7.70152 0.666687 7.33333Z"
        fill={color}
      />
    </svg>
  );
}
