import React from "react";

const Profile = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="34"
      height="38"
      viewBox="0 0 34 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.8333 4.49967H22.17C21.4 2.37301 19.3833 0.833008 17 0.833008C14.6167 0.833008 12.6 2.37301 11.83 4.49967H4.16667C2.15 4.49967 0.5 6.14967 0.5 8.16634V33.833C0.5 35.8497 2.15 37.4997 4.16667 37.4997H29.8333C31.85 37.4997 33.5 35.8497 33.5 33.833V8.16634C33.5 6.14967 31.85 4.49967 29.8333 4.49967ZM17 4.49967C18.0083 4.49967 18.8333 5.32467 18.8333 6.33301C18.8333 7.34134 18.0083 8.16634 17 8.16634C15.9917 8.16634 15.1667 7.34134 15.1667 6.33301C15.1667 5.32467 15.9917 4.49967 17 4.49967ZM17 11.833C20.0433 11.833 22.5 14.2897 22.5 17.333C22.5 20.3763 20.0433 22.833 17 22.833C13.9567 22.833 11.5 20.3763 11.5 17.333C11.5 14.2897 13.9567 11.833 17 11.833ZM28 33.833H6V31.2663C6 27.5997 13.3333 25.583 17 25.583C20.6667 25.583 28 27.5997 28 31.2663V33.833Z"
        fill={color}
      />
    </svg>
  );
};

export default Profile;
