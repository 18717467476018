import React, { useEffect } from "react";

const FormComponent = () => {
  useEffect(() => {
    window.location.replace(
      String(process.env.REACT_APP_LMS_APP_BASE_URL).concat("/login"),
    );
  }, []);

  return <></>;
};

export default FormComponent;
