import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../components/atoms/Container";
import logo from "../../assets/home/logo-dark.svg";

const Header = styled.header`
  position: relative;
  background: #fff;
  box-shadow:
    0px 0.25px 3px 0px rgba(0, 0, 0, 0.04),
    0px 2.75px 9px 0px rgba(0, 0, 0, 0.19);
  & .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    z-index: 999;
  }

  .logo img {
    height: 35px;
  }

  @media screen and (max-width: 600px) {
    .logo img {
      height: 30px;
    }
  }
`;

const HeaderComponent = () => {
  return (
    <Header className="header">
      <Container>
        <div className="wrapper">
          <div className="logo">
            <Link to="/">
              <img
                src={logo}
                alt={`${String(
                  process.env.REACT_APP_TRIBE_NAME,
                ).toLowerCase()}-lending`}
              />
            </Link>
          </div>
        </div>
      </Container>
    </Header>
  );
};

export default HeaderComponent;
