import React from "react";
import styled from "styled-components";
import BulletPoint from "./BulletPoint";

const bulletPoints = [
  "Approvals in seconds",
  "Deposits in minutes",
  "Safe. Fast. Secure.",
  "Built for you",
];

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 230px 230px;
  grid-gap: 24px;

  @media screen and (max-width: 530px) {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
`;

export const BulletPoints = () => {
  return (
    <Wrapper className="bullet-points-wrapper">
      {bulletPoints.map((label) => {
        return <BulletPoint key={label} label={label} />;
      })}
    </Wrapper>
  );
};

export default BulletPoints;
