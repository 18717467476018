import React, { useEffect, useState } from "react";
import {
  resendOTPApi,
  sendLeadOTPApi,
  verifyLeadApi,
} from "../../../api/application";
import { routes } from "../../../routes/Application/routes";
import PrivateRoute from "../../../routes/Application/PrivateRoute";
import PageLayout from "../../../layouts/application/Page/Layout";
import {
  NumberContainer,
  Container,
  Header,
  Line,
  NumberHolder,
  Row,
  Submit,
  TextAction,
  TextRegular,
  TextSubtle,
  ErrorBadge,
  SuccessBadge,
} from "./styles";
import OtpInput from "react-otp-input";
import logo from "../../../assets/svgs/logo-dark.svg";
import alertCircle from "../../../assets/svgs/alert-circle.svg";
import successCircle from "../../../assets/svgs/success-circle.svg";
import verifyIcon from "../../../assets/png/verify.png";
import { toast } from "react-toastify";
import { useUserData } from "../../../contexts/user";
import { useHistory } from "react-router-dom";
import PageLoader from "../../../components/molecules/Loaders/LoaderWrapper";

const OtpInputProxy: any = OtpInput;

const OTPVerification = () => {
  const codeLength = 5;
  const [error, setError] = useState("");
  const [code, setCode] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [phone, setPhone] = useState("");
  const [leadId] = useState(localStorage.getItem("leadId")!);
  const [isSuccess, setSuccess] = useState(false);
  const history = useHistory();

  const { fetchUser, clearLead } = useUserData();

  const onFailedOtp = () => {
    localStorage.clear();
    history.push("/contact");
  };

  const onCompletedOtp = (verifyData: any) => {
    const { userToken, screenTrackingId } = verifyData;
    localStorage.setItem("userToken", userToken);
    localStorage.setItem("screenTrackingId", screenTrackingId);
    clearLead();
    fetchUser();
    history.push("/application");
  };

  useEffect(() => {
    if (!leadId) {
      return onFailedOtp();
    }
    (async () => {
      const response = await verifyLeadApi({ leadId });
      if (response?.data?.phoneNumber) {
        setPhone(response?.data?.phoneNumber);
      } else if (response?.data?.isVerified) {
        onCompletedOtp(response.data);
      } else {
        onFailedOtp();
      }
      setLoading(false);
    })();
  }, []);

  const handleChange = (value: string) => {
    if (error) setError("");
    setCode(value);
  };

  const resendOTP = async () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    const leadId = localStorage.getItem("leadId");
    if (leadId) {
      await resendOTPApi({ leadId });
    } else {
      toast.error("missing lead information");
    }
    setLoading(false);
  };

  const onSubmitHandler = async () => {
    setLoading(true);
    const result = await sendLeadOTPApi({
      code,
      leadId: leadId,
    });

    setLoading(false);
    const errorMessage: string = result?.error?.message as any;
    if (result.status === 403) {
      toast.error("Maximum attempt count reached");
      onFailedOtp();
    } else if (result?.data?.success && !errorMessage) {
      setSuccess(true);
      toast.success("Thank you!");
      const response = await verifyLeadApi({ leadId });
      onCompletedOtp(response.data);
    } else if (errorMessage) {
      setError(errorMessage);
    }
  };

  const formatPhoneNumber = (number: string): string => {
    return !number
      ? ""
      : "(" +
          number.slice(0, 3) +
          ") " +
          number.slice(3, 6) +
          " - " +
          number.slice(6);
  };

  return (
    <>
      <PrivateRoute route={routes.VERIFY_OTP}>
        <PageLayout route={routes.VERIFY_OTP}>
          <Container className="otp-container">
            <div className="background"></div>
            <img
              src={logo}
              alt={`${String(
                process.env.REACT_APP_TRIBE_NAME,
              ).toLowerCase()}-lending`}
            />
            <Row className="head">
              <Header>
                <img src={verifyIcon} alt="Verify" />
                <h1>Verify Your Phone Number</h1>
              </Header>
            </Row>
            <Row>
              <Line />
            </Row>
            <div className="card">
              <NumberContainer>
                <TextSubtle>We sent a verification link to: </TextSubtle>
                <NumberHolder>{formatPhoneNumber(phone)}</NumberHolder>
                <TextSubtle>Please type the verification code</TextSubtle>
              </NumberContainer>
              <PageLoader loading={isLoading}>
                <Row>
                  <OtpInputProxy
                    disabled={isLoading}
                    value={code}
                    onChange={handleChange}
                    numInputs={codeLength}
                    separator={<span style={{ width: "4px" }} />}
                    isInputNum
                    placeholder="-----"
                    focusStyle={{
                      width: "45px",
                      height: "42px",
                      color: "#58595B",
                      background: "#EFEFEF",
                      borderRadius: "5px",
                      border: "none",
                      textAlign: "center",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Spartan",
                      fontSize: "25p",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "32px",
                    }}
                    inputStyle={{
                      width: "45px",
                      height: "42px",
                      color: "#58595B",
                      background: "#EFEFEF",
                      borderRadius: "5px",
                      border: "none",
                      textAlign: "center",
                      fontFeatureSettings: "'clig' off, 'liga' off",
                      fontFamily: "Spartan",
                      fontSize: "25p",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "32px",
                    }}
                    shouldAutoFocus
                    className="input-container"
                  />
                </Row>
              </PageLoader>

              {error && (
                <ErrorBadge>
                  <img src={alertCircle} alt="Error"></img> {error}
                </ErrorBadge>
              )}
              {isSuccess && (
                <SuccessBadge>
                  <img src={successCircle} alt="Success"></img> Success
                </SuccessBadge>
              )}
              <Row>
                <TextRegular>Not seeing a text?</TextRegular>
                <TextAction
                  onClick={resendOTP}
                  className={`${isLoading ? "disabled" : ""}`}
                >
                  Resend Message
                </TextAction>
              </Row>

              <Row>
                <Submit
                  onClick={onSubmitHandler}
                  disabled={code.length < 5 || isLoading}
                >
                  Submit code
                </Submit>
              </Row>
            </div>
          </Container>
        </PageLayout>
      </PrivateRoute>
    </>
  );
};
export default OTPVerification;
