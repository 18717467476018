import styled from "styled-components";
import bg from "../../../../assets/landing/dedication-bg.svg";

export const Wrapper = styled.section`
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.5);
  z-index: 100;
  padding: 120px 0 60px;
  position: relative;

  .background {
    background-image: url(${bg});
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: -100px;
    bottom: 0;
    right: 0;
  }

  .bullet-point {
    color: #fff;
  }

  .content {
    display: flex;
    align-items: center;
    gap: 7rem;
    z-index: 100;
    position: relative;
    padding: 0 6rem;

    .main-img {
      border-radius: 2.6rem;
    }

    .logo {
      height: 6.4rem;
    }

    .logo-wrapper {
      display: flex;
    }

    .information {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      text-align: left;

      .heading {
        font-size: 4.4rem;
      }

      .heading,
      p {
        line-height: 1.5;
        color: #fff;
      }

      p {
        font-size: 12px;
        font-weight: 700;
        max-width: 450px;
      }
    }
  }

  @media screen and (max-width: 1130px) {
    .content {
      flex-direction: column;
      gap: 24px;
    }
  }

  @media screen and (max-width: 550px) {
    padding: 45px 0;

    .main-img {
      width: 100%;
    }
    .background {
      background-size: contain;
      left: -50px;
      &:before {
        content: "";
        background: #1571b2;
        position: absolute;
        top: 130px;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
    .content {
      padding: 0;

      & .information .heading {
        font-size: 32px;
        line-height: 1.2;
      }
    }
  }

  @media screen and (max-width: 360px) {
    .background:before {
      top: 100px;
    }
  }
`;
