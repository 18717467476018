import styled from "styled-components";

export const Wrapper = styled.section`
  padding: 5.4rem 5.4rem 12rem;
  .text-container {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
    .application-description {
      color: #fff;
    }

    .renew-approval h1 {
      max-width: 45rem;
    }
  }

  .form-wrapper {
    background: #fff;
    padding: 3.6rem;
    border-radius: 1.4rem;
    box-shadow:
      0px 350px 98px 0px rgba(0, 0, 0, 0),
      0px 224px 90px 0px rgba(0, 0, 0, 0.01),
      0px 126px 76px 0px rgba(0, 0, 0, 0.04),
      0px 56px 56px 0px rgba(0, 0, 0, 0.06),
      0px 14px 31px 0px rgba(0, 0, 0, 0.07);

    button {
      height: 44px;
      &[type="submit"] {
        background: #be1e2d;
        color: #fff;
      }

      border: 1px solid #be1e2d;
      color: #be1e2d;
    }

    form {
      width: 44rem;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 2rem 1rem;
  }
`;

export const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 3.6rem;

  @media screen and (max-width: 1130px) {
    flex-direction: column;
    gap: 24px;

    h1.heading {
      max-width: unset;
    }
  }

  @media screen and (max-width: 992px) {
    .form-wrapper form {
      width: auto;
    }
  }

  @media screen and (max-width: 600px) {
    .form-wrapper {
      padding: 0;
      border-radius: 0;
      box-shadow: none;
      &,
      form {
        width: 100%;
      }
      & form {
        gap: 12px;

        & .fields,
        & .button-wrapper {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }

        .button-wrapper {
          align-items: center;
        }
        .preApproval-button {
          max-width: 230px;
        }
      }
    }
  }
`;

export const ApprovalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.6rem;

  .subheading {
    padding: 1rem;
    background: #00ce9d;
    text-align: center;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 700;
    border-radius: 1.2rem;
  }

  .description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    p {
      line-height: 1.5;
    }
  }

  @media screen and (min-width: 1300px) {
    width: 64rem;
    padding-right: 6rem;
  }

  @media screen and (max-width: 530px) {
    .heading {
      font-size: 32px;
      line-height: 1.2;
    }
    .subheading {
      font-size: 13px;
    }
    .description-wrapper {
      order: 4;
    }
    .bullet-points-wrapper {
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
    }
  }
`;
