import React, { useState, useEffect } from "react";
import { useUserData } from "./user";

export const stepsKey = (steps) => {
  // used for checking if collection of steps has
  // changed by comparing its numbers in useEffect's
  // (may happen when leadType is loaded and default steps are replaced)
  return steps.map((s) => s.number).join();
};

export const StepperContext = React.createContext();

export const StepperProvider = ({
  children,
  steps: state,
  setSteps: setState,
  initialStep,
}) => {
  const [currentStep, setCurrentStep] = useState("");
  const [previousStep, setPreviousStep] = useState();
  const { user } = useUserData();
  const index = (number) => state.findIndex((i) => i.number === number);
  const stepsNumbers = stepsKey(state);

  useEffect(() => {
    const updatedState = state.map((item, itemIndex) => {
      const updatedItem = { ...item };
      if (itemIndex < index(initialStep)) {
        updatedItem.active = false;
        updatedItem.completed = true;
      } else if (item.number === initialStep) {
        updatedItem.active = true;
      } else {
        updatedItem.active = false;
      }
      return updatedItem;
    });

    setCurrentStep(initialStep);
    setState(updatedState);
  }, [initialStep, stepsNumbers]);

  const goToStep = (stepNumber, keepPreviousStep = false) => {
    const currIndex = index(currentStep);
    const newIndex = index(stepNumber);
    const updatedState = state.map((item) => {
      const newItem = { ...item };
      if (item.number === stepNumber) {
        newItem.active = true;
        if (keepPreviousStep) {
          setPreviousStep(currentStep);
        }
        setCurrentStep(newItem.number);
      } else if (newIndex > index(item.number)) {
        newItem.completed = true;
        newItem.active = false;
      } else {
        newItem.active = false;
      }
      return newItem;
    });
    updatedState[currIndex].active = false;
    updatedState[currIndex].completed = true;
    setState([...updatedState]);
  };

  const moveToPreviousStep = () => {
    goToStep(previousStep);
    setPreviousStep();
  };

  const moveToNextStep = () => {
    if (!previousStep) {
      const currIndex = state.findIndex((item) => item.number === currentStep);
      const newState = [...state];
      newState[currIndex].active = false;
      newState[currIndex].completed = true;
      let newIndex = currIndex + 1;
      let nextStep = newState[newIndex].number;
      while (newState[newIndex].remarketing) {
        newIndex++;
        nextStep = newState[newIndex].number;
      }
      newState[newIndex].active = true;
      setCurrentStep(nextStep);
      setState(newState);
    } else {
      moveToPreviousStep();
    }
  };

  const editStepForm = (stepNumber) => {
    const index = state.findIndex((item) => item.number === stepNumber);
    const newState = [...state];
    newState[index].editing = true;
    setState(newState);
    goToStep(stepNumber);
  };

  // SEND REQUEST FROM THE TABLE OR TABLE MODAL WITH FURHTER TABLE UPDATE
  const expose = {
    goToStep,
    steps: state,
    moveToNextStep,
    currentStep,
    editStepForm,
    moveToPreviousStep,
    initialStep,
  };
  return (
    <StepperContext.Provider value={expose}>{children}</StepperContext.Provider>
  );
};

export const useStepper = () => {
  const context = React.useContext(StepperContext);

  if (context === undefined) {
    throw new Error("table must be used within a TableProvider");
  }
  return context;
};
