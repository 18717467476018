import React from "react";

const Contact = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.72 12.7597C17.07 11.9097 17.26 10.9997 17.26 9.9997C17.26 9.2797 17.15 8.5897 16.96 7.9497C16.31 8.0997 15.63 8.1797 14.92 8.1797C13.466 8.18126 12.0329 7.83322 10.7415 7.16493C9.45018 6.49663 8.33843 5.52766 7.5 4.3397C6.6031 6.50988 4.91112 8.25551 2.77 9.2197C2.73 9.4697 2.73 9.7397 2.73 9.9997C2.73 10.9544 2.91804 11.8998 3.2834 12.7818C3.64875 13.6638 4.18425 14.4653 4.85933 15.1404C6.22272 16.5038 8.07188 17.2697 10 17.2697C11.05 17.2697 12.06 17.0397 12.97 16.6297C13.54 17.7197 13.8 18.2597 13.78 18.2597C12.14 18.8097 10.87 19.0797 10 19.0797C7.58 19.0797 5.27 18.1297 3.57 16.4197C2.536 15.3889 1.76737 14.1227 1.33 12.7297H0V8.1797H1.09C1.42024 6.57222 2.17949 5.08411 3.28719 3.87329C4.39489 2.66248 5.80971 1.77411 7.38153 1.30246C8.95335 0.830812 10.6235 0.793474 12.2149 1.19441C13.8062 1.59534 15.2593 2.4196 16.42 3.5797C17.6802 4.83496 18.5398 6.4358 18.89 8.1797H20V12.7297H19.94L16.38 15.9997L11.08 15.3997V13.7297H15.91L16.72 12.7597ZM7.27 9.7697C7.57 9.7697 7.86 9.8897 8.07 10.1097C8.28105 10.3225 8.39947 10.61 8.39947 10.9097C8.39947 11.2094 8.28105 11.4969 8.07 11.7097C7.86 11.9197 7.57 12.0397 7.27 12.0397C6.64 12.0397 6.13 11.5397 6.13 10.9097C6.13 10.2797 6.64 9.7697 7.27 9.7697ZM12.72 9.7697C13.35 9.7697 13.85 10.2797 13.85 10.9097C13.85 11.5397 13.35 12.0397 12.72 12.0397C12.09 12.0397 11.58 11.5397 11.58 10.9097C11.58 10.6074 11.7001 10.3174 11.9139 10.1036C12.1277 9.88981 12.4177 9.7697 12.72 9.7697Z"
        fill={color}
      />
    </svg>
  );
};

export default Contact;
