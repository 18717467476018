/* eslint-disable*/
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUserData } from "../../../contexts/user";
import { checkUnderwriting } from "../../../utils/underwritingStatus";
import { useAppContextData } from "../../../contexts/global";
type IProps = {
  children: any;
  route: string;
};

const PrivateRoute = ({ children, route }: IProps) => {
  const { user, loading } = useUserData();
  const { data } = useAppContextData();
  const history = useHistory();

  const hasPopulatedData = Object.keys(data || {}).length > 0;

  const { declined } = checkUnderwriting(
    user?.data?.underwritingDecision?.status,
  );

  const userApplicationDeclinedUrl = "/application/thankyou";
  const userApplicationDocRequiredUrl = "/application/docrequired";

  const isDirectMailReview =
    user.data?.leadData?.leadType === "direct-mail" &&
    user.data?.leadData?.decision?.status === "review";

  const screenId = localStorage.getItem("screenTrackingId");
  const leadId = localStorage.getItem("leadId");
  if (
    history.location.pathname === "/application" &&
    !(screenId || leadId) &&
    !hasPopulatedData
  ) {
    history.push("/");
  }

  // redirect the authorized user on the declined page screen
  if (
    isDirectMailReview &&
    history?.location?.pathname !== userApplicationDocRequiredUrl &&
    user?.data?.user?.hasPasswordSet === true
  ) {
    history.push(userApplicationDocRequiredUrl);
    return <></>;
  }
  if (
    !loading &&
    isDirectMailReview &&
    history?.location?.pathname !== userApplicationDocRequiredUrl &&
    user?.data?.user?.hasPasswordSet === true
  ) {
    history.push(userApplicationDocRequiredUrl);
    return <></>;
  }
  if (
    !loading &&
    !isDirectMailReview &&
    user?.isAuthorized &&
    declined &&
    user?.data?.user?.hasPasswordSet === true &&
    history?.location?.pathname !== userApplicationDeclinedUrl
  ) {
    history.push(userApplicationDeclinedUrl);
    return <></>;
  }
  const isProcessing =
    localStorage.getItem("isProcessingApplication") === "true";
  if (
    !loading &&
    !user?.isAuthorized &&
    [userApplicationDeclinedUrl, userApplicationDocRequiredUrl].includes(
      history?.location?.pathname,
    ) &&
    !isProcessing &&
    !hasPopulatedData
  ) {
    history.push("/application");
    return <></>;
  }
  if (
    isProcessing &&
    ![userApplicationDeclinedUrl].includes(history?.location?.pathname)
  ) {
    history.push("/application/thankyou");
    return <></>;
  }
  if (
    !loading &&
    user?.isAuthorized &&
    !declined &&
    [userApplicationDeclinedUrl, userApplicationDocRequiredUrl].includes(
      history?.location?.pathname,
    )
  ) {
    history.push("/application");
    return <></>;
  }

  // otherwise load the route that matches the current url
  return children;
};

export default PrivateRoute;
