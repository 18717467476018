import Modal from "../../../../../organisms/Modal/Regular";
import React from "react";

interface IIdlePrompt {
  open: boolean;
  remaining: number;
  handleStillHere: () => void;
}

const IdlePrompt = ({ open, remaining, handleStillHere }: IIdlePrompt) => {
  const content = () => (
    <button onClick={handleStillHere} style={{ padding: "10px" }}>
      I`m still here
    </button>
  );

  return (
    <Modal
      modalTitle={`Are you still here? Logging out in ${remaining} seconds`}
      modalContent={content}
      open={open}
      handleClose={handleStillHere}
    />
  );
};

export default IdlePrompt;
