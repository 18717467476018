import React from "react";
import Apply from "../../components/templates/landing/Apply";
import Dedication from "../../components/templates/landing/Dedication/Dedication-Approved";
import About from "../../components/templates/landing/About";
import DetailsButton from "../../components/templates/landing/DetailsButton";
import Layout from "../../layouts/landing";
import Approval from "../../components/templates/landing/Apply/Approval";
import aboutImage from "../../assets/landing/about.png";
import { Theme } from "./styles";
import dedication from "../../assets/landing/dedication.png";

const Landing = () => {
  return (
    <Theme>
      <Layout>
        <Apply sectionOne={<Approval />} />
        <Dedication image={dedication} />
        <About
          image={aboutImage}
          title="WE ARE AMICUS LENDING"
          companyName="Amicus Lending"
        />
        <DetailsButton />
      </Layout>
    </Theme>
  );
};

export default Landing;
