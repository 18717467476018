export const APPLICATION_BASE_ROUTE = "/application";

export const pageName = {
  LOGIN: "login",
  MAGIC_LOGIN: "login/magic",
  REGISTRATION: "registration",
  BORROWER_PORTAL: "borrower",
  START: "start",
  REMARKETING: "remarketing",
  TERMS_OF_USE: "terms-of-use",
  PRIVACY_NOTICE: "privacy-notice",
  ONLINE_PRIVACY_NOTICE: "online-privacy-notice",
  THANKYOU: "thankyou",
  VERIFY_OTP: "verification",
  DOCREQUIRED: "docrequired",
};

const {
  LOGIN,
  MAGIC_LOGIN,
  BORROWER_PORTAL,
  START,
  TERMS_OF_USE,
  PRIVACY_NOTICE,
  ONLINE_PRIVACY_NOTICE,
  THANKYOU,
  VERIFY_OTP,
  DOCREQUIRED,
  REMARKETING,
} = pageName;

export const routes = {
  HOME: `${APPLICATION_BASE_ROUTE}/`,
  VERIFY_OTP: `${APPLICATION_BASE_ROUTE}/${VERIFY_OTP}`,
  REMARKETING: `${APPLICATION_BASE_ROUTE}/${REMARKETING}`,
  LOGIN: `${APPLICATION_BASE_ROUTE}/${LOGIN}`,
  MAGIC_LOGIN: `${APPLICATION_BASE_ROUTE}/${MAGIC_LOGIN}`,
  BORROWER_PORTAL: `/${BORROWER_PORTAL}/`,
  START: `${APPLICATION_BASE_ROUTE}/${START}/`,
  TERMS_OF_USE: `/${TERMS_OF_USE}`,
  PRIVACY_NOTICE: `/${PRIVACY_NOTICE}`,
  ONLINE_PRIVACY_NOTICE: `/${ONLINE_PRIVACY_NOTICE}`,
  THANKYOU: `${APPLICATION_BASE_ROUTE}/${THANKYOU}`,
  DOCREQUIRED: `${APPLICATION_BASE_ROUTE}/${DOCREQUIRED}`,
};
