import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Form } from "../styles";
import Button from "../../../../../../atoms/Buttons/Button";
import Header from "../../../../Components/FormHeader";
import FlinksWizard from "./flinksWizard";
import { useUserData } from "../../../../../../../contexts/user";
import {
  saveFlinksLoginId,
  verifyBankCredentials,
  checkBankVerifyType,
  saveBankDataForAccelitas,
} from "../../../../../../../api/application";
import { useStepper } from "../../../../../../../contexts/steps";
import Loader from "../../../../../../molecules/Loaders/LoaderWrapper";
import { errorHandler } from "../../../../../../../utils/errorHandler";

import { initForm, renderFields, validateForm } from "./config";
import bankRoutingNumberImg from "../../../../../../../assets/png/bank-routing-number.jpg";

const FrameWrapper = styled.div`
  margin: 24px 0;

  & .btn-manual {
    margin-top: 24px;
  }

  & iframe {
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    & {
      margin: 12px 0;
    }
    .logo img {
      max-width: 80%;
    }
  }
`;

const ButtonsWrapper = styled.div`
  max-width: 342px;
  width: 100%;
  .btn {
    &-continue,
    &-manual {
      width: 100%;
    }
    &-manual {
      margin-top: 12px;
    }
  }
`;

const Auto = (props: any) => {
  const { fetchUser, screenId, user } = useUserData();
  const { moveToNextStep } = useStepper();
  const [isFlinksLoggedIn, setIsFlinksLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form, setForm] = useState(initForm({}));
  const [verifyType, setVerifyType] = useState("");
  const { isActive } = props;

  const title = "Checking Account Verification";
  const iframeUrl = process.env.REACT_APP_FLINKS_URL;

  const setBankInfo = () => {
    const bankInfo = user?.data?.bankInfo;
    if (bankInfo) {
      setForm(
        initForm({
          bankName: bankInfo.financialInstitution,
          accountNumber: bankInfo.accountNumber,
          routingNumber: bankInfo.routingNumber,
        }),
      );
    }
  };

  const onFetchLoginId = async ({
    loginId,
    requestId,
    bankName,
    accountId,
    screenTrackingId,
  }: any) => {
    const payload = {
      screenTrackingId,
      loginId,
      requestId,
      bankName,
      selectedAccountId: accountId,
    };
    if (loginId && accountId && requestId && bankName) {
      setLoading(true);
      await saveFlinksLoginId(payload);
      setIsFlinksLoggedIn(true);
      setLoading(false);
    } else if (!loginId) {
      // TOAST ERROR HERE THAT FLINKS LOGIN ID NOT RETRIEVED
      errorHandler({ error: "Flinks Not initiated Try Again" });
    }
  };

  const runCheckBankVerifyType = async () => {
    setLoading(true);
    const response: any = await checkBankVerifyType(screenId);
    if (response && response.verifyType) {
      setVerifyType(response.verifyType);
    } else {
      toast.error(response.error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (typeof screenId !== "undefined" && isActive) {
      runCheckBankVerifyType();
    }
    setBankInfo();
  }, [screenId, isActive]);

  const onBankDataSubmitHandler = async (e: any) => {
    e.preventDefault();
    const [isValid, validatedForm] = validateForm(form);
    if (isValid) {
      setLoading(true);
      const requestData = {
        bankName: form.bankName.value,
        accountNumber: form.accountNumber.value,
        routingNumber: form.routingNumber.value,
      };

      const response: any = await saveBankDataForAccelitas(
        screenId,
        requestData,
      );
      const result = await fetchUser();
      if (response.ok && !response.error) {
        if (response.data.showFlinks) {
          setVerifyType("flinks");
        } else {
          moveToNextStep();
        }
        // toast.error(response?.error?.message);
      }

      if (
        ["failed", "queued"].includes(
          result?.data?.underwritingDecision?.status,
        )
      ) {
        history.push("/application/thankyou");
        return null;
      }
      setLoading(false);
    } else {
      // SET FORM VALIDATION ERRORS
      setForm(validatedForm);
    }
    return null;
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    // IF ID VERIFICATION IS REQUIRED IT WILL TRIGGER MODAL VIA USER CONTEXT AND SET FURTHER ACTIONS FROM THE MODAL
    // -- search: #identityVerificationModal
    const response: any = await verifyBankCredentials({
      screenTrackingId: screenId,
    });

    const result = await fetchUser();
    if (response.ok && !response.error) {
      moveToNextStep();
      // toast.error(response?.error?.message);
    }

    if (
      ["failed", "queued"].includes(result?.data?.underwritingDecision?.status)
    ) {
      history.push("/application/thankyou");
      return null;
    }

    setLoading(false);
    return null;
  };

  const FlinksNote = () => {
    return (
      <span>
        We need to verify the account details where your loan funds will be
        deposited. Please login to your online banking account below to verify
        your account. This instant bank verification process is safe and secure
        and we do not keep your login details.
        <br />
        <br />
        <b>
          Please select your primary checking account. It might take a few
          minutes to connect to your bank account, please be patient.
        </b>
        <br />
        <br />
        <b>
          Paypal, prepaid debit accounts and payment cards are not accepted as
          valid bank accounts.
        </b>
      </span>
    );
  };

  const AccelitasNote = () => {
    return (
      <span>
        Please enter your Primary Checking Account Number, the 9-digit Account
        Routing Number and your Bank Name below.
        <br />
        Your primary checking account is the account into which your income is
        deposited.
      </span>
    );
  };

  const onChangeHandler = (e: any) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.value, message: "" },
    }));
  };

  if (verifyType === "accelitas") {
    return (
      <Loader loading={loading}>
        <Form onSubmit={onBankDataSubmitHandler}>
          <Header title={title} note={AccelitasNote()} />
          {renderFields(form).map(({ component: Component, ...field }) => {
            return (
              <Component
                key={field.name}
                {...field}
                onChange={onChangeHandler}
              />
            );
          })}
          <img
            src={bankRoutingNumberImg}
            alt="Bank routing hint"
            width="100%"
          />
          <Button type="submit" variant="primary">
            Confirm
          </Button>
        </Form>
      </Loader>
    );
  }
  if (verifyType === "flinks") {
    return (
      <Loader loading={loading}>
        <Form>
          <Header title={title} note={FlinksNote()} />
          <FrameWrapper>
            <FlinksWizard
              iframeUrl={iframeUrl}
              screenId={screenId}
              height="400"
              onFetchLoginId={onFetchLoginId}
            />
          </FrameWrapper>
          <ButtonsWrapper>
            <div>
              {isFlinksLoggedIn && (
                <Button
                  type="button"
                  variant="primary"
                  className="btn-continue"
                  onClick={(e) => onSubmitHandler(e)}
                >
                  Continue
                </Button>
              )}
            </div>
          </ButtonsWrapper>
        </Form>
      </Loader>
    );
  }
  return <div>...</div>;
};

export default Auto;
