import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  background: #00ce9d;
  display: flex;
  justify-content: center;
  height: 6rem;
  align-items: center;
  font-weight: 600;
  font-size: 14px;

  .link {
    color: #fff;
    text-decoration: underline;
    text-align: center;
  }
`;
const DetailsButton = () => {
  return (
    <Wrapper className="details-button">
      <Link className="link" to="/">
        *Click here for more details.
      </Link>
    </Wrapper>
  );
};

export default DetailsButton;
