import React, { useState } from "react";
import { toast } from "react-toastify";
import OtpInput from "react-otp-input";
import {
  sendOTPApi,
  resendOTPApi,
  sendLeadOTPApi,
  verifyLeadApi,
} from "../../../../../../api/application";
import Wrapper, { Button } from "./styles";
import Loader from "../../../../../molecules/Loaders/LoaderWrapper";
import Counter from "./Counter";
import Header from "../Header";
import { LEAD_TYPE } from "../../../../../../api/types";
import { useUserData } from "../../../../../../contexts/user";

const OtpInputProxy: any = OtpInput;

const initTimer = 5;

const OTP = ({ closeModal, state: { screenId, moveToNextStep } }: any) => {
  const codeLength = 5;
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState(initTimer);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { fetchUser, leadId, clearLead } = useUserData();

  const handleChange = (value: string) => {
    if (error) setError("");
    setCode(value);
  };

  const resendOTP = async () => {
    setLoading(true);
    const leadId = localStorage.getItem("leadId");
    if (leadId || screenId) {
      if (leadId) {
        await resendOTPApi({ leadId });
      } else {
        await resendOTPApi({ screenTrackingId: screenId });
      }
      setSeconds(initTimer);
    } else {
      toast.error("missing screenTracking id");
    }
    setLoading(false);
  };

  const onSubmitHandler = async () => {
    if (!screenId && !leadId) {
      toast.error("no screentracking id, connection error");
    }
    if (code.length !== codeLength) {
      setError(`The code length should be ${codeLength} digits`);
    } else {
      setLoading(true);
      let result: any;

      if (leadId) {
        result = await sendLeadOTPApi({
          code,
          leadId,
        });
      } else {
        result = await sendOTPApi({
          code,
          screenTrackingId: screenId,
        });
      }

      setLoading(false);
      const errorMessage: string = result?.error?.message;
      if (result && !errorMessage) {
        const user = await fetchUser();
        // IF THE USER IS A LEAD - JUST CLOSE THE MODAL AND KEEP THE USER ON SAME SCREEN
        closeModal();
        const isWebLead = user?.data?.leadData?.leadType === LEAD_TYPE.WEB_LEAD;

        // leadId present - application was in pending state and required otp verification
        // clear leadId, fetch user and screenTracking id
        if (leadId) {
          const response = await verifyLeadApi({ leadId });
          const { userToken, screenTrackingId } = response.data;
          localStorage.setItem("userToken", userToken);
          localStorage.setItem("screenTrackingId", screenTrackingId);
          clearLead();
          fetchUser();
        } else if (user?.data?.origin === "WEB" || isWebLead) {
          moveToNextStep();
        }
        toast.success("Thank you!");
      } else if (errorMessage) {
        setError(errorMessage);
      }
    }
  };

  return (
    <Loader loading={loading}>
      <Wrapper>
        <Header text="Please enter the OTP code sent to your cellphone." />
        <div className="otp-container">
          <OtpInputProxy
            value={code}
            onChange={handleChange}
            numInputs={codeLength}
            separator={<span style={{ width: "4px" }} />}
            isInputNum
            inputStyle={{
              height: "45px",
              width: "45px",
            }}
            shouldAutoFocus
            className="input-container"
          />
          {error ? <div className="error-message">{error}</div> : ""}
        </div>
        <div className="form-footer">
          <Button type="button" onClick={onSubmitHandler}>
            Verify code
          </Button>
          {seconds ? (
            <Counter count={seconds} setCount={setSeconds} />
          ) : (
            <Button type="button" onClick={resendOTP}>
              Resend OTP
            </Button>
          )}
        </div>
      </Wrapper>
    </Loader>
  );
};

export default OTP;
