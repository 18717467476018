import React from "react";

const Email = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66602 10.9997C3.66602 8.97463 5.30764 7.33301 7.33268 7.33301H36.666C38.6911 7.33301 40.3327 8.97463 40.3327 10.9997V32.9997C40.3327 35.0247 38.6911 36.6663 36.666 36.6663H7.33268C5.30764 36.6663 3.66602 35.0247 3.66602 32.9997V10.9997ZM10.1168 10.9997L21.9993 21.3969L33.8819 10.9997H10.1168ZM36.666 13.4357L23.2066 25.2127C22.5154 25.8175 21.4833 25.8175 20.7921 25.2127L7.33268 13.4357V32.9997H36.666V13.4357Z"
        fill={color}
      />
    </svg>
  );
};

export default Email;
