import React, { useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { H2, Note } from "../../../atoms/Typography";
import PageContainer from "../ApplicationFlow/styles";
import { useUserData } from "../../../../contexts/user";
import logo from "../../../../assets/svgs/logo-dark.svg";
import Button from "../../../atoms/Buttons/Button";
import { tribesConfig } from "../../../../app.config";

const Container = styled(PageContainer)`
  margin: 5vh auto;
`;

const Wrapper = styled.div`
  .logo {
    margin: 0 auto 10px;
    display: block;
  }
  .note {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  img {
    & svg path {
      fill: red !important;
    }
  }
  .title {
    margin-bottom: 24px;
  }
  .docList {
    margin-left: 24px;
    margin-bottom: 24px;
  }
  .docList li {
    line-height: 1.5;
  }
  .linkBlock {
    text-align: center;
  }
  .linkBlock a {
    font-size: 16px;
  }
`;

const DocRequiredMessage = () => {
  const { user } = useUserData();

  const goToPortal = () => {
    const borrowerPortalLink = process.env.REACT_APP_LMS_APP_BASE_URL;
    window.location.replace(String(borrowerPortalLink).concat("/login"));
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <img src={logo} alt={`${tribesConfig.name}-lending`} className="logo" />
        <h3 className="title">
          You need to upload following documents to complete your application.
        </h3>
        <ul className="docList">
          {user?.data?.leadData?.decision?.requiredDocs?.map((doc: any) => (
            <li key={doc}>{doc}</li>
          ))}
        </ul>
        <div className="linkBlock">
          <Button
            type="button"
            className="printButton"
            variant="primary"
            onClick={goToPortal}
          >
            Go to borrower portal to upload documents
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
};

export default DocRequiredMessage;
