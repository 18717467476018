import React from "react";

export default function Ok({ size = "14", fill = "none", color = "#1E84BE" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00004 1.66732C4.05452 1.66732 1.66671 4.05513 1.66671 7.00065C1.66671 9.94617 4.05452 12.334 7.00004 12.334C9.94556 12.334 12.3334 9.94617 12.3334 7.00065C12.3334 4.05513 9.94556 1.66732 7.00004 1.66732ZM0.333374 7.00065C0.333374 3.31875 3.31814 0.333984 7.00004 0.333984C10.6819 0.333984 13.6667 3.31875 13.6667 7.00065C13.6667 10.6826 10.6819 13.6673 7.00004 13.6673C3.31814 13.6673 0.333374 10.6826 0.333374 7.00065ZM10.1096 4.83571C10.3848 5.08032 10.4096 5.50171 10.165 5.77689L6.60942 9.77689C6.48291 9.91922 6.30158 10.0007 6.11115 10.0007C5.92073 10.0007 5.73939 9.91922 5.61288 9.77689L3.8351 7.77689C3.59049 7.50171 3.61528 7.08032 3.89046 6.83571C4.16565 6.5911 4.58704 6.61589 4.83165 6.89108L6.11115 8.33052L9.16844 4.89108C9.41305 4.61589 9.83443 4.5911 10.1096 4.83571Z"
        fill={color}
      />
    </svg>
  );
}
