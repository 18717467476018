import React from "react";
import { H1 } from "../../../atoms/Typography";
import { ApprovalWrapper as Wrapper } from "./styles";
import BulletPoints from "../BulletPoints";

const RenewApproval = () => {
  return (
    <Wrapper className="section-apply">
      <H1 className="heading renew-approval">Renew Your Approval Now!</H1>
      <div className="subheading">For Immediate Cash Loans up to $2000</div>
      <div className="description-wrapper">
        <p>
          Resecure your approval today! Enter your desired loan amount, purpose,
          and a few pieces of identifying information to start the near-instant
          application process and get approved now!
        </p>
        <p>
          Trusted borrowers are eligible for the best rates, rapid application
          processes, and same-day funding. Most borrowers receive their approval
          in minutes and get cash deposit in less than an hour!
        </p>
      </div>
      <BulletPoints />
    </Wrapper>
  );
};

export default RenewApproval;
