import React from "react";
import Container from "../../../atoms/Container";
import logo from "../../../../assets/svgs/logo-light.svg";
import { H2 as Heading } from "../../../atoms/Typography";
import { Wrapper } from "./styles";
import { tribesConfig } from "../../../../app.config";

const Dedication = ({ image }: { image: any }) => {
  return (
    <Wrapper>
      <div className="background" />
      <Container>
        <div className="content">
          <img className="main-img" src={image} alt="family" />
          <div className="information">
            <div className="logo-wrapper">
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Heading className="heading">
              Dedicated to your continued financial wellbeing.
            </Heading>
            <p className="description">
              We are the trusted partner to get you the money you need, fast.
              Auto repairs, doctor visits, late paychecks – whatever.
              {tribesConfig.name} Lending is here to lend a helping hand.
              Whether it’s your first time borrowing or fifth, we offer you the
              best rates and fastest lending process. To get you the help you
              need now.
            </p>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Dedication;
