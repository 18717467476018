import React from "react";
import Apply from "../../components/templates/landing/Apply";
import Dedication from "../../components/templates/landing/Dedication/Dedication-RenewApproval";
import About from "../../components/templates/landing/About";
import DetailsButton from "../../components/templates/landing/DetailsButton";
import Layout from "../../layouts/landing";
import RenewApproval from "../../components/templates/landing/Apply/RenewApproval";
import image from "../../assets/landing/about.png";
import dedication from "../../assets/landing/dedication.png";
import { Theme } from "./styles";

const Landing = () => {
  return (
    <Theme>
      <Layout>
        <Apply sectionOne={<RenewApproval />} />
        <Dedication image={dedication} />
        <About
          image={image}
          title="WE ARE AMICUS LENDING"
          companyName="Amicus Lending"
        />
        <DetailsButton />
      </Layout>
    </Theme>
  );
};

export default Landing;
