import React from "react";

const Phone = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.2556 27.9769L30.5989 27.4452C29.4806 27.3169 28.3806 27.7019 27.5922 28.4902L24.2189 31.8636C19.0306 29.2236 14.7772 24.9886 12.1372 19.7819L15.5289 16.3902C16.3172 15.6019 16.7022 14.5019 16.5739 13.3836L16.0422 8.76355C15.8222 6.91189 14.2639 5.51855 12.3939 5.51855H9.22223C7.15056 5.51855 5.42723 7.24189 5.55556 9.31355C6.52723 24.9702 19.0489 37.4736 34.6872 38.4452C36.7589 38.5736 38.4822 36.8502 38.4822 34.7786V31.6069C38.5006 29.7552 37.1072 28.1969 35.2556 27.9769V27.9769Z"
        fill={color}
      />
    </svg>
  );
};

export default Phone;
