import React from "react";
import Button from "../../atoms/Buttons/Button";

const DirectMailActionButton = () => {
  return String(process.env.REACT_APP_DIRECT_MAIL_URL).length ? (
    <div
      className="button-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <a
        href={process.env.REACT_APP_DIRECT_MAIL_URL}
        target="_blank"
        style={{
          color: "black",
          textDecoration: "none",
        }}
        rel="noreferrer"
      >
        <Button
          type="button"
          variant="secondary"
          className="button-contained button-secondary"
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            Pre-approved mail recipients click HERE
          </div>
        </Button>
      </a>
    </div>
  ) : null;
};

export default DirectMailActionButton;
