import React from "react";
import { H1 } from "../../../atoms/Typography";
import { ApprovalWrapper as Wrapper } from "./styles";

const Approval = () => {
  return (
    <Wrapper className="section-apply">
      <H1 className="heading approved-heading">You’re Approved!</H1>
      <div className="subheading">For Immediate Cash Loans up to $2000</div>
      <div className="description-wrapper">
        <p>
          To ensure a secure borrowing experience, please enter your phone
          number and your social below so we can verify your identity for your
          direct cash deposit.
        </p>
        <p>
          You will receive a custom link and be immediately directed to your
          loan agreement. Complete your loan agreement and receive an immediate
          cash deposit to your bank account.
        </p>
      </div>
    </Wrapper>
  );
};

export default Approval;
