/* eslint no-underscore-dangle:0 */
import React, { useState, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import { useInView } from "react-intersection-observer";
import PromissoryNote from "./Ric";
import SignaturePad from "./SignaturePad";
import {
  saveSignature,
  getUserSignatureContent,
  createSignedDocuments,
  acceptTerm,
  getUserLoanDocuments,
} from "../../../../../../api/application";
import { useUserData } from "../../../../../../contexts/user";
import Loader from "../../../../../molecules/Loaders/LoaderWrapper";
import ErrorMessage from "../../../../../molecules/Form/Elements/FormError";
import Button from "../../../../../atoms/Buttons/Button";
import { useIsMobile } from "../../../../../../hooks/mobile";
import { useHistory } from "react-router-dom";

const Styles = styled.div`
  width: 100%;
  border: 1px solid var(--color-grey-light);
  border-radius: 14px;
  background: #fff;
  padding: 24px;

  .note {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  .img-wrapper {
    margin-right: 40px;
  }

  @media screen and (max-width: 1024px) {
    padding: 12px;
  }

  .jumpToSignatureButton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 12px 12px;
  }
`;

const Form = styled.form`
  .heading {
    margin-bottom: 20px;
  }

  .form-layout {
    margin-bottom: 20px;

    .buttons {
      display: flex;
      width: 270px;
      justify-content: space-between;
      padding: 10px;
    }

    .printButton {
      display: block;
      margin: 0 auto;
    }

    height: 498px;
    position: relative;
    overflow: auto;
    /* margin-bottom: 40px; */

    .ricContent {
      max-width: 100%;
      overflow: hidden;
    }
  }

  button {
    &:hover {
      box-shadow: none;
    }
  }
`;

function FormComponent({
  moveToNextStep,
  isActive,
}: {
  moveToNextStep: any;
  isActive: boolean;
}) {
  const isMobile = useIsMobile();
  const [signature, setSignature] = useState<string | null>(null);
  const ricContent = useRef<any>();
  const { user, fetchUser, screenId } = useUserData();
  const [loanDocData, setLoanDocData] = useState<any>(null);
  const [error, setError] = useState("");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // const [pdf, setPdf] = useState("");

  const signatureRef = useRef<HTMLDivElement | null>(null);
  const { ref: inViewRef, inView } = useInView();
  const setSignatureRef = useCallback(
    (node) => {
      signatureRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  const handleJumpToSignature = useCallback(() => {
    signatureRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);

  useEffect(() => {
    if (
      user?.data?.userSignaturePath &&
      !user?.data?.signatureContent &&
      isActive
    ) {
      getUserSignatureContent(screenId).then((res) => {
        setSignature(`data:image/png;base64,${res.data.content}`);
      });
    }
  }, [user, isActive, screenId]);

  useEffect(() => {
    const fetchDocuments = async () => {
      if (!loanDocData && user?.data && isActive) {
        setLoading(true);
        const result = await getUserLoanDocuments(user.data.id);
        setLoading(false);
        if (result && !result.error) {
          setLoanDocData(result?.data?.data);
        } else if (result.error) {
          setError(result.error.message || "failed to fetch documents");
        }
      }
    };
    fetchDocuments();
  }, [loanDocData, user, isActive]);

  const handleSave = useCallback(
    async (data: string) => {
      setSaving(true);
      setError("");

      const fileTypeRegex = /data:(.*);base64/gi;
      const [imageMeta, base64Content] = data.split(",");
      const [, fileType = ""] = fileTypeRegex.exec(imageMeta) ?? [];
      const payload = {
        fileType,
        data: base64Content,
      };
      const signatureResponse = await saveSignature({
        payload,
        screenTrackingId: screenId,
      });
      if (signatureResponse.error) {
        setSaving(false);
        return setError(signatureResponse.error.message);
      }
      await acceptTerm({
        documentType: "patriaAgreement",
        screenTrackingId: screenId,
      });
      setSignature(data);

      const result = await createSignedDocuments(screenId);
      await fetchUser();

      const message = result?.error?.message || "";
      if (result?.error) {
        toast.error(message);
        if (
          message === "Your bank account is missing. Please contact to support."
        ) {
          localStorage.clear();
          history.push("/contact");
          return;
        }
      } else if (result) {
        moveToNextStep();
        toast.success("Contract Signed and Submitted");
      }

      setSaving(false);
    },
    [fetchUser, loanDocData, moveToNextStep, screenId, signature, user?.data],
  );

  const handlePrint = useReactToPrint({
    content: () => ricContent.current,
  });

  // const contractProps = useMemo(
  //   () => ({
  //     loanDocData: user?.data || loanDocData,
  //     paymentData: loanDocData?.paymentData || {},
  //     userSignature: signature,
  //   }),
  //   [loanDocData, signature, user?.data]
  // );
  // const generatePdfHandler = async () => {
  //   const res = await generatePdf({
  //     component: <PromissoryNote {...contractProps} isPdf />,
  //     screenId,
  //   });
  //   if (res) setPdf(res);
  // };

  if (!isActive) return <></>;

  if (!user || !user.data) return <Styles>something went wrong</Styles>;

  return (
    <Loader loading={loading}>
      <Styles>
        <Form>
          <div className="form-layout">
            <div className="ricContent" ref={ricContent}>
              <PromissoryNote
                loanDocData={user?.data ?? loanDocData}
                paymentData={loanDocData?.paymentData ?? {}}
                userSignature={signature}
              />
            </div>
            <div ref={setSignatureRef}>
              <Loader loading={saving || loading}>
                <SignaturePad
                  data={user?.data?.signatureContent}
                  onSave={handleSave}
                />
              </Loader>
              {!isMobile && (
                <Button
                  type="button"
                  className="printButton"
                  variant="primary"
                  onClick={handlePrint}
                >
                  Print
                </Button>
              )}
            </div>
          </div>

          {!inView && (
            <Button
              className="jumpToSignatureButton"
              onClick={handleJumpToSignature}
              type="button"
              variant="primary"
            >
              Jump to Signature
            </Button>
          )}

          <ErrorMessage message={error} />
        </Form>
      </Styles>
    </Loader>
  );
}

export default FormComponent;
