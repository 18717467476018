import { useState } from "react";

const useLocalStorage = (key: string, initialValue: string) => {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? storedValue : initialValue;
  const [value, setValue] = useState<string | null>(initial);

  const updateValue = (newValue: string) => {
    setValue(newValue);
    localStorage.setItem(key, newValue);
  };

  const clearValue = () => {
    setValue(null);
    localStorage.removeItem(key);
  };

  return [value, updateValue, clearValue];
};

export default useLocalStorage;
