import React from "react";
import Form from "../../home/Apply/Form";
import Container from "../../../atoms/Container";
import { Layout, Wrapper } from "./styles";

const Section = ({ sectionOne }: { sectionOne: any }) => {
  return (
    <Wrapper>
      <Container>
        <Layout className="layout">
          <div className="text-container">{sectionOne}</div>
          <div className="form-wrapper">
            <Form applyButtonLabel="Renew Now" />
          </div>
        </Layout>
      </Container>
    </Wrapper>
  );
};

export default Section;
