import React from "react";
import { Route, Switch } from "react-router-dom";
import { routes as route } from "./routes";
import Login from "../../pages/application/authorization/login";
import MagicLogin from "../../pages/application/authorization/magicLogin";
import NotFound from "../../pages/application/notfound";
import { UserProvider } from "../../contexts/user";
import Application from "../../pages/application/applicationflow";
import Declined from "../../pages/application/declined";
import OTPVerification from "../../pages/application/otp";
import DocRequired from "../../pages/application/docrequired";

const Routes = () => {
  return (
    <UserProvider>
      <Switch>
        <Route path={route.THANKYOU} exact component={Declined} />
        <Route path={route.VERIFY_OTP} exact component={OTPVerification} />
        <Route path={route.REMARKETING} exact component={Application} />
        <Route path={route.HOME} exact component={Application} />
        <Route path={route.LOGIN} exact component={Login} />
        <Route path={route.MAGIC_LOGIN} exact component={MagicLogin} />
        <Route path={route.DOCREQUIRED} exact component={DocRequired} />
        <Route component={NotFound} />
      </Switch>
    </UserProvider>
  );
};

export default Routes;
