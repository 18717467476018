import React from "react";
import styled from "styled-components";
import Form from "./Form";
import BannerWrapper from "../../../atoms/Landing/Banner";
import Container from "../../../atoms/Container";
import { H1, H2, H5 } from "../../../atoms/Typography";

const Wrapper = styled.section`
  .text-container {
    display: flex;
    flex-direction: column;
    row-gap: 3.2rem;
    .application-description {
      color: #fff;
    }

    h1 {
      max-width: 45rem;
    }
  }

  .form-wrapper {
    background: #fff;
    padding: 3.6rem;
    border-radius: 1.4rem;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 480px;

    & h2 {
      color: var(--color-main-2);
    }
    form {
      width: 57rem;
      flex-grow: 1;
    }

    @media screen and (max-width: 560px) {
      form {
        width: 56rem;
      }
    }

    @media screen and (max-width: 501px) {
      form {
        width: 47rem;
      }
    }

    @media screen and (max-width: 436px) {
      form {
        width: 40rem;
      }
    }

    @media screen and (max-width: 397px) {
      form {
        width: 35rem;
      }
    }
  }
`;

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;

  .section-start-application {
    height: auto;
  }

  @media screen and (max-width: 767px) {
    &.layout {
      flex-direction: column !important;
    }
    row-gap: 2rem;

    .text-container {
      row-gap: 2rem;
      h1 {
        width: 100%;
        font-size: 3.2rem;
      }
    }
  }

  @media screen and (max-width: 438px) {
    &.layout {
      flex-direction: column !important;
    }
    row-gap: 1rem;

    .text-container {
      row-gap: 1rem;
      h1 {
        width: 100%;
        font-size: 3rem;
      }
    }
  }
`;

const Section = () => {
  return (
    <Wrapper>
      <BannerWrapper className="section-start-application">
        <Container>
          <Layout className="layout">
            <div className="text-container">
              <H1>Get the money you need in minutes</H1>
              <H5 className="application-description">
                Offering personal loans up to $2,500 to help manage life’s
                unexpected events. Big or small, we are always here to help you!
              </H5>
            </div>
            <div className="form-wrapper">
              <H2>Start Application</H2>
              <Form />
            </div>
          </Layout>
        </Container>
      </BannerWrapper>
    </Wrapper>
  );
};

export default Section;
