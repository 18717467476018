import React from "react";
import Banner from "../../components/templates/home/Resources/Banner";
import Desription from "../../components/templates/home/Resources/Desription";

const Resources = () => {
  return (
    <div>
      <Banner />
      <Desription />
    </div>
  );
};

export default Resources;
