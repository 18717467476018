import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Stepper from "../../../organisms/Stepper";
import clock from "../../../../assets/png/clock.png";
import { Note } from "../../../atoms/Typography";
import { StepperProvider, stepsKey } from "../../../../contexts/steps";
import {
  DEFAULT_STEPS,
  DIRECT_MAIL_STEPS,
  LEAD_STEPS,
  RE_BORROWER_STEPS,
  SET_PASSWORD_STEP,
  UNCONVERTED_LEAD_STEPS,
} from "./config";
import Loader from "../../../molecules/Loaders/LoaderWrapper";
import { useUserData } from "../../../../contexts/user";
import Popup from "./Popup";
import { LEAD_TYPE } from "../../../../api/types";
import { useIdleTimer } from "react-idle-timer";
import { runProcessIdleApplication } from "../../../../api/application";
import IdlePrompt from "./Popup/Idle";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  & > .page-heading-wrapper {
    margin-bottom: 50px;
    & .note-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 28px;

      & img {
        margin-right: 16px;
      }
    }
    & h1 {
      font-size: 56px;
      line-height: 62px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 767px) {
    & > .page-heading-wrapper .heading {
      font-size: 32px;
      line-height: 36px;
    }
  }
`;

// apply logic for defining stepper based on lead type
const getSteps = (applicationType: LEAD_TYPE, hasPasswordSet: boolean) => {
  let steps: Record<string, any>[];
  switch (applicationType) {
    case LEAD_TYPE.DIRECT_MAIL:
      steps = DIRECT_MAIL_STEPS;
      break;
    case LEAD_TYPE.QUALIFIED:
      steps = LEAD_STEPS;
      break;
    case LEAD_TYPE.RE_BORROWER:
      steps = RE_BORROWER_STEPS;
      break;
    case LEAD_TYPE.UNCONVERTED_LEAD:
      steps = UNCONVERTED_LEAD_STEPS;
      break;
    default:
      steps = DEFAULT_STEPS;
      break;
  }

  return hasPasswordSet ? steps : [SET_PASSWORD_STEP, ...steps];
};

const Application = () => {
  const { loading, user } = useUserData();
  const { leadType, id: leadId } = user?.data?.leadData || {};
  const hasPasswordSet = user?.data?.user?.hasPasswordSet;
  const [steps, setSteps] = useState(getSteps(leadType, hasPasswordSet));
  const isApprovedLead = [LEAD_TYPE.QUALIFIED, LEAD_TYPE.DIRECT_MAIL].includes(
    leadType,
  );
  const isIdleAcceptable = [
    LEAD_TYPE.RE_BORROWER,
    LEAD_TYPE.UNCONVERTED_LEAD,
  ].includes(leadType);
  const [lastLevel, setLastLevel] = useState(
    user?.data?.lastlevel || DEFAULT_STEPS[0].number || 1,
  );
  const [prompt, openPrompt] = useState(false);
  const timeout = 600_000;
  const promptBeforeIdle = 60_000;
  const [remaining, setRemaining] = useState<number>(timeout);
  const history = useHistory();

  const onIdle = async () => {
    if (lastLevel > 5 || !isIdleAcceptable) return;

    openPrompt(false);
    runProcessIdleApplication(leadId);
    localStorage.clear();
    history.push("/application/thankyou");
  };

  const onPrompt = () => {
    if (lastLevel > 5 || !isIdleAcceptable) return;

    openPrompt(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    timeout,
    promptBeforeIdle,
    throttle: 500,
    onIdle,
    onPrompt,
  });

  const handleStillHere = () => {
    activate();
    openPrompt(false);
  };

  useEffect(() => {
    const leadSteps = getSteps(leadType, hasPasswordSet);
    const isSameSteps = stepsKey(leadSteps) === stepsKey(steps);
    if (!isSameSteps) {
      setSteps(leadSteps);
    }
  }, [steps, leadType]);

  useEffect(() => {
    const lastLevel = user?.data?.lastlevel;
    const hasPasswordSet = user?.data?.user?.hasPasswordSet;
    if (
      user?.data &&
      leadType !== LEAD_TYPE.WEB_LEAD &&
      hasPasswordSet === false
    ) {
      setLastLevel("1-Password");
    } else if (lastLevel) {
      setLastLevel(lastLevel);
    }
  }, [user?.data?.user?.hasPasswordSet, user?.data?.lastlevel]);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  let titleDesc = isApprovedLead
    ? "You have been approved. Before signing the documents and receiving funding, you need to set up a password"
    : "It will take about 5 minutes to fill the form and less than a minute to get a decision. Check it out!";
  let titleHead = isApprovedLead
    ? "You have been approved"
    : "Start Application";
  if (user?.data?.underwritingDecision?.status === "queued") {
    titleDesc =
      "We require additional information to process your application. Please create a password to continue.";
    titleHead = "Continue Application";
  }

  return (
    <Loader loading={loading}>
      <Wrapper>
        <div className="page-heading-wrapper">
          <div className="note-wrapper">
            <img src={clock} alt="clock" />
            <Note className="note">{titleDesc}</Note>
          </div>
          <h1 className="heading">{titleHead}</h1>
        </div>
        <StepperProvider
          steps={steps}
          initialStep={lastLevel}
          setSteps={setSteps}
        >
          <div className="stepper-wrapper">
            <Stepper />
          </div>
          <Popup />
        </StepperProvider>
        {prompt && (
          <IdlePrompt
            open={prompt}
            handleStillHere={handleStillHere}
            remaining={remaining}
          />
        )}
      </Wrapper>
    </Loader>
  );
};

export default Application;
