import React from "react";
import styled from "styled-components";
import { ReactComponent as CheckMark } from "../../../../assets/landing/checkmark-bold.svg";

const BulletPointWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
`;

const BulletPoint = ({ label }: { label: string }) => {
  return (
    <BulletPointWrapper className="bullet-point">
      <CheckMark />
      {label}
    </BulletPointWrapper>
  );
};

export default BulletPoint;
