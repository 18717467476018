import React from "react";
import styled from "styled-components";
import Header from "./Header";
import Footer from "../Footer";

const Wrapper = styled.div`
  position: relative;

  main {
    min-height: calc(100vh - var(--header-height) - var(--footer-height));
  }
`;

const Layout = ({ children }: any) => {
  return (
    <Wrapper className="layout">
      <div>
        <Header />
      </div>
      <main>{children}</main>
      <Footer />
    </Wrapper>
  );
};

export default Layout;
