import React, { useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import Button from "../../../../../atoms/Buttons/Button";
import { initForm, renderFields } from "./config";
import { validateForm } from "./validate";
import Header from "../../../Components/FormHeader";
import Container from "../../styles";
import { parseFormToRequest } from "../../../../../../utils/parseForm";
import Loader from "../../../../../molecules/Loaders/LoaderWrapper";
import { updateUserPassword } from "../../../../../../api/application";
import { useAppContextData } from "../../../../../../contexts/global";
import { useUserData } from "../../../../../../contexts/user";
import { useHistory } from "react-router-dom";

const Form = styled.form`
  .textField {
    margin-bottom: 12px;
    width: 100%;
  }

  & .confirm-btn {
    margin-top: 24px;
  }

  & button[type="submit"] {
    margin-top: 24px;
  }

  @media screen and (max-width: 500px) {
    .textField:first-child {
      margin-top: 12px;
    }
  }
`;

const FormComponent = ({
  editing,
  isActive,
  goToStep,
}: {
  moveToNextStep: any;
  editing: boolean;
  isActive: boolean;
  goToStep: any;
}) => {
  const { data } = useAppContextData();
  const [form, setForm] = useState(initForm(data));
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useUserData();
  const history = useHistory();

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    const [isValid, validatedForm] = validateForm(form, editing);

    if (isValid) {
      setLoading(true);
      const payload = parseFormToRequest(validatedForm) as any;
      const updateUserRes = await updateUserPassword(payload);
      if (updateUserRes && !updateUserRes.error) {
        setUser((prev: { data: { user: object } }) => {
          return {
            ...prev,
            data: {
              ...prev.data,
              user: { ...prev.data.user, hasPasswordSet: true },
            },
          };
        });
        if (
          // user.data?.leadData?.leadType === "direct-mail" &&
          user.data?.leadData?.decision?.status === "review"
        ) {
          history.push("/application/docrequired");
        } else {
          goToStep(user.data.lastlevel);
        }
      } else {
        toast.error("something went wrong");
      }
      setLoading(false);
    } else {
      // SET FORM VALIDATION ERRORS
      setForm(validatedForm);
    }
  };

  const onChangeHandler = (e: any) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: { value: e.target.value, message: "" },
    }));
  };

  const title = "Set password";
  const note = "";

  if (!isActive) {
    return <></>;
  }

  return (
    <Container>
      <Loader loading={loading}>
        <Form onSubmit={onSubmitHandler}>
          <Header title={title} note={note} />
          {renderFields(form, true).map(
            ({ component: Component, ...field }) => {
              return (
                <Component
                  disabled={loading}
                  key={field.name}
                  {...field}
                  onChange={onChangeHandler}
                />
              );
            },
          )}

          <Button type="submit" variant="primary">
            Confirm
          </Button>
        </Form>
      </Loader>
    </Container>
  );
};

export default FormComponent;
