import React from "react";

export default function Print({ size = "16", color = "#222222" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00065 7.33398C6.00065 6.96579 6.29913 6.66732 6.66732 6.66732H9.33398C9.70217 6.66732 10.0007 6.96579 10.0007 7.33398C10.0007 7.70217 9.70217 8.00065 9.33398 8.00065H6.66732C6.29913 8.00065 6.00065 7.70217 6.00065 7.33398Z"
        fill="#222222"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33398 4.00065L3.33398 2.66732C3.33398 1.93094 3.93094 1.33398 4.66732 1.33398H11.334C12.0704 1.33398 12.6673 1.93094 12.6673 2.66732V4.00065L13.334 4.00065C14.0704 4.00065 14.6673 4.5976 14.6673 5.33398V10.6673C14.6673 11.4037 14.0704 12.0007 13.334 12.0007H12.6673V13.334C12.6673 14.0704 12.0704 14.6673 11.334 14.6673L4.66732 14.6673C3.93094 14.6673 3.33398 14.0704 3.33398 13.334L3.33398 12.0007H2.66732C1.93094 12.0007 1.33398 11.4037 1.33398 10.6673V5.33399C1.33398 4.59761 1.93094 4.00065 2.66732 4.00065L3.33398 4.00065ZM4.66732 2.66732L11.334 2.66732V4.00065L4.66732 4.00065V2.66732ZM2.66732 5.33399L2.66732 10.6673L3.33398 10.6673C3.33398 9.93094 3.93094 9.33399 4.66732 9.33399L11.334 9.33398C12.0704 9.33398 12.6673 9.93094 12.6673 10.6673H13.334V5.33398L2.66732 5.33399ZM4.66732 11.334L4.66732 13.334L11.334 13.334V10.6673H4.66732L4.66732 11.334Z"
        fill={color}
      />
    </svg>
  );
}
