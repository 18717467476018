import React from "react";

const Account = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13Z"
        fill={color}
      />
      <path
        d="M9 14.9996C6.33 14.9996 1 16.3396 1 18.9996V19.9996C1 20.5496 1.45 20.9996 2 20.9996H16C16.55 20.9996 17 20.5496 17 19.9996V18.9996C17 16.3396 11.67 14.9996 9 14.9996ZM15.47 7.76962C15.79 8.55962 15.79 9.43962 15.47 10.2296C15.28 10.6996 15.36 11.2296 15.72 11.5896L15.75 11.6196C16.33 12.1996 17.32 12.0796 17.7 11.3496C18.46 9.89962 18.46 8.19962 17.68 6.68962C17.3 5.94962 16.3 5.80962 15.71 6.39962L15.7 6.40962C15.36 6.75962 15.28 7.29962 15.47 7.76962ZM19.18 2.88962C18.78 3.28962 18.72 3.90962 19.05 4.36962C21.02 7.10962 21.01 10.7796 19.02 13.6196C18.7 14.0696 18.77 14.6896 19.16 15.0796L19.19 15.1096C19.68 15.5996 20.51 15.5596 20.93 15.0096C23.68 11.4696 23.69 6.63962 20.93 2.98962C20.51 2.43962 19.67 2.39962 19.18 2.88962Z"
        fill={color}
      />
    </svg>
  );
};

export default Account;
