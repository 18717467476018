import React from "react";

export default function Edit({
  size = "13px",
  fill = "none",
  color = "#222222",
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 13"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.86193 0.528575C9.12228 0.268226 9.54439 0.268226 9.80474 0.528575L12.4714 3.19524C12.7318 3.45559 12.7318 3.8777 12.4714 4.13805L3.80474 12.8047C3.67971 12.9297 3.51014 13 3.33333 13H0.666667C0.298477 13 0 12.7015 0 12.3333V9.66665C0 9.48984 0.0702379 9.32027 0.195262 9.19524L6.86179 2.52871L8.86193 0.528575ZM7.33333 3.94279L1.33333 9.94279V11.6666H3.05719L9.05719 5.66665L7.33333 3.94279ZM10 4.72384L11.0572 3.66665L9.33333 1.94279L8.27614 2.99998L10 4.72384Z"
        fill={color}
      />
    </svg>
  );
}
