import TextField from "../../../../../../molecules/Form/Fields/TextField";

export const initForm = ({
  bankName = "",
  routingNumber = "",
  accountNumber = "",
}) => {
  return {
    bankName: { value: bankName || "", message: "" },
    routingNumber: { value: routingNumber || "", message: "" },
    accountNumber: { value: accountNumber || "", message: "" },
  };
};

const isNumber = (str) => {
  if (typeof str !== "string") {
    return false;
  }

  if (str.trim() === "") {
    return false;
  }
  return !Number.isNaN(Number(str));
};

export const renderFields = (form) => [
  {
    value: form.bankName.value,
    name: "bankName",
    component: TextField,
    placeholder: "Bank Name",
    message: form.bankName.message,
  },
  {
    value: form.routingNumber.value,
    name: "routingNumber",
    component: TextField,
    placeholder: "Routing Number",
    message: form.routingNumber.message,
    maxLength: 9,
  },
  {
    value: form.accountNumber.value,
    name: "accountNumber",
    component: TextField,
    placeholder: "Account Number",
    message: form.accountNumber.message,
  },
];

export const validateForm = (form) => {
  const newForm = { ...form };
  let isValid = true;
  Object.keys(newForm).forEach((key) => {
    if (key === "bankName" && newForm.bankName.value.trim().length < 1) {
      isValid = false;
      newForm.bankName.message = "enter bank name";
    } else if (
      key === "routingNumber" &&
      (newForm.routingNumber.value.trim().length !== 9 ||
        !isNumber(newForm.routingNumber.value))
    ) {
      isValid = false;
      newForm.routingNumber.message = "enter a valid routingNumber";
    } else if (
      key === "accountNumber" &&
      newForm.accountNumber.value.trim().length < 1
    ) {
      isValid = false;
      newForm.accountNumber.message = "enter accountNumber";
    }
  });
  return [isValid, newForm];
};
