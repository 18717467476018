import React from "react";
import PageLayout from "../../../layouts/application/Page/Layout";
import { routes } from "../../../routes/Application/routes";
import PrivateRoute from "../../../routes/Application/PrivateRoute";
import DocRequiredMessage from "../../../components/templates/application/DocRequired";

const DocRequired = () => {
  return (
    <PrivateRoute route={routes.DOCREQUIRED}>
      <PageLayout route={routes.DOCREQUIRED}>
        <DocRequiredMessage />
      </PageLayout>
    </PrivateRoute>
  );
};

export default DocRequired;
