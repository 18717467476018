import { useEffect, useState } from "react";

const MOBILE_BREAKPOINT = 1024;

export const useIsMobile = () => {
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  return MOBILE_BREAKPOINT >= width;
};
