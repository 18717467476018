import React from "react";
import Container from "../../../atoms/Container";
import logo from "../../../../assets/svgs/logo-light.svg";
import { H2 as Heading } from "../../../atoms/Typography";
import BulletPoints from "../BulletPoints";
import { Wrapper } from "./styles";
import { tribesConfig } from "../../../../app.config";

const Dedication = ({ image }: { image: any }) => {
  return (
    <Wrapper className="section-dedication">
      <div className="background" />
      <Container>
        <div className="content">
          <img className="main-img" src={image} alt="family" />
          <div className="information">
            <div className="logo-wrapper">
              <img className="logo" src={logo} alt="logo" />
            </div>
            <Heading className="heading">
              We&apos;re dedicated to your financial success.
            </Heading>
            <BulletPoints />
            <p className="description">
              We&apos;re your trusted partner to get you the money you need
              fast. Whether it&apos;s auto repairs, doctor visits, late
              paychecks – whatever.  {tribesConfig.name} Lending is here to lend
              a hand.
            </p>
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Dedication;
