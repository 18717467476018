import Password from "../../../../../molecules/Form/Fields/Password";

export const initForm = (changePassword = false) => {
  if (changePassword) {
    return {
      password: { value: "", message: "" },
      repassword: { value: "", message: "" },
    };
  }

  return {
    password: { value: "", message: "" },
    repassword: { value: "", message: "" },
  };
};

export const renderFields = (form, passwordChange = false) => {
  if (passwordChange) {
    return [
      {
        value: form.password.value,
        name: "password",
        component: Password,
        placeholder: "Create Password",
        message: form.password.message,
      },
      {
        value: form.repassword.value,
        name: "repassword",
        component: Password,
        placeholder: "Repeat Password",
        message: form.repassword.message,
      },
    ];
  }

  return [
    {
      value: form.password.value,
      name: "password",
      component: Password,
      placeholder: "Create Password",
      message: form.password.message,
    },
    {
      value: form.repassword.value,
      name: "repassword",
      component: Password,
      placeholder: "Repeat Password",
      message: form.repassword.message,
    },
  ];
};
