import { validatePassword } from "../../../../../../utils/validators/password";

export const validateForm = (form, editing) => {
  const newForm = { ...form };
  Object.keys(newForm).forEach((key) => {
    if (!editing && key === "password") {
      const [isPasswordValid, passwordMessage, repasswordMessage] =
        validatePassword({
          password: newForm[key].value,
          repassword: newForm.repassword.value,
        });

      if (!isPasswordValid) {
        newForm.password.message = passwordMessage;
        newForm.repassword.message = repasswordMessage;
      }
    }
  });
  const isValid = !Object.values(newForm).some((val) => val.message.length > 0);
  return [isValid, newForm];
};
