import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../../../atoms/Buttons/Button";
import { Note, H3 } from "../../../../../atoms/Typography";
import ButtonWrapper from "../../../../../atoms/Form/Buttons-wrapper";

const Wrapper = styled.div`
  padding: 24px;
  border-radius: 14px;
  margin: 24px 0;
  background: #fbfbff;
  & h2 {
    font-weight: 700;
  }

  & .note {
    margin: 12px 0;
  }

  .btn-secondary {
    background: transparent;
  }

  .sigcanvas {
    border: 1px solid var(--color-border);
    background: #fff;
    border-radius: 14px;
    margin: 10px 0;
    height: 200px;
    max-width: 997px;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    padding: 12px;
  }
`;

interface SignaturePadProps {
  data?: string;
  disabled?: boolean;
  onSave: (dataURL: string) => void;
}

function SignaturePad({ data, disabled, onSave }: SignaturePadProps) {
  const ref = useRef<SignatureCanvas | null>(null);

  useEffect(() => {
    if (data) {
      const canvas = ref.current?.getCanvas();
      const ctx = canvas?.getContext("2d");
      const image = new Image();
      image.onload = () => {
        ctx?.drawImage(image, 0, 0);
      };
      image.src = `data:image/png;base64,${data}`;
    }
  }, [data]);

  const [empty, setEmpty] = useState(true);

  const handleEnd = useCallback(() => {
    setEmpty(ref.current?.isEmpty() ?? true);
  }, []);

  const handleSave = useCallback(() => {
    const newData = ref.current?.getTrimmedCanvas().toDataURL();
    if (newData != null) {
      onSave(newData);
    }
  }, [onSave]);

  return (
    <Wrapper>
      <H3>Create your signature</H3>
      <Note className="note color-text">
        Please click and hold your mouse to sign your signature in the box
        below. You will need to sign the fields below with your signature.
      </Note>
      <SignatureCanvas
        ref={ref}
        canvasProps={{
          className: "sigcanvas",
        }}
        onEnd={handleEnd}
      />
      <ButtonWrapper>
        <Button
          disabled={disabled || empty}
          type="button"
          variant="primary"
          onClick={handleSave}
        >
          Sign Document
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default SignaturePad;
