import React from "react";

const Card = ({ color = "#1E84BE" }) => {
  return (
    <svg
      width="38"
      height="30"
      viewBox="0 0 38 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.3327 3.99968C37.3327 1.97463 35.6911 0.333008 33.666 0.333008H4.33268C2.30764 0.333008 0.666016 1.97463 0.666016 3.99967V25.9997C0.666016 28.0247 2.30764 29.6663 4.33268 29.6663H33.666C35.6911 29.6663 37.3327 28.0247 37.3327 25.9997V3.99968ZM33.666 7.66634H4.33268L4.33268 3.99967L33.666 3.99968V7.66634ZM4.33268 13.1663H33.666V25.9997L4.33268 25.9997L4.33268 13.1663Z"
        fill={color}
      />
    </svg>
  );
};

export default Card;
