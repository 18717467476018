import styled from "styled-components";

export const Theme = styled.div`
  .section-apply .subheading {
    background: #016ec8;
  }

  .bullet-point svg {
    path {
      fill: #ff6b00;
    }
  }
  .form-wrapper .button-wrapper {
    button[type="submit"] {
      background: #ff6b00;
      border: 1px solid #ff6b00;
    }

    .button-wrapper button {
      border: 1px solid #ff6b00;
      color: #ff6b00;
    }
  }

  .section-dedication {
    .background {
      @media screen and (max-width: 550px) {
        &:before {
          background: #006ec9;
        }
      }
    }
  }

  .section-about {
    .logo-wrapper {
      color: #ff6b00;
    }
  }

  .details-button {
    background: #ff6b00;
  }
`;
