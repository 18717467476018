import { validateEmail } from "../../../../utils/validators/email";

export const validateForm = (formData) => {
  const form = { ...formData };
  Object.keys(form).forEach((key) => {
    if (
      key === "phoneNumber" &&
      form?.phoneNumber?.value?.replace("_", "").trim().length !== 10
    ) {
      form.phoneNumber.message = "Enter a valid phone number";
    } else if (key === "email") {
      const emailIsValid = validateEmail(form.email.value);
      if (!emailIsValid) {
        form.email.message = "Enter a valid email";
      }
    } else if (key === "reason" && !form?.reason?.value?.trim()) {
      form.reason.message = "Select a reason";
    } else if (key === "ssn") {
      const ssn = form?.ssn?.value?.trim().replace("_", "");
      if (!ssn) {
        form.ssn.message = "This field is required";
      } else if (ssn.length !== 9) {
        form.ssn.message = "Enter a valid number";
      }
    } else if (key === "requestedLoan") {
      if (!form.requestedLoan.value || +form.requestedLoan.value <= 0) {
        form.requestedLoan.message = "This field is required";
      } else if (form.requestedLoan.value > 2500) {
        form.requestedLoan.message = "loan amount offers is upto $2500";
      }
    }
  });

  const isValid = !Object.values(form).some((val) => val.message.length > 0);
  return [isValid, form];
};

/*
  const validateForm = () => {
	if (!form.requestedLoan.value || +form.requestedLoan.value <= 0) {
	  return false;
	} else if (form.requestedLoan.value > 2500) {
	  return false;
	} else if (!form.reason.value) {
	  return false;
	} else if (form.ssn.value?.trim().replace("_", "").length !== 4) {
	  return false;
	} else if (!validateEmail(form.email.value)) {
	  return false;
	} else if (form.phoneNumber.value?.replace("_", "").trim().length !== 10) {
	  return false;
	}
	return true;
  };
*/
