import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ApplicationRoutes from "./Application/Router";
import StylesSheet from "../pages/styles";
import Home from "./Home";
import Approved from "../pages/landing/Approved";
import RenewApproval from "../pages/landing/RenewApproval";

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/styles" component={StylesSheet} />
        <Route path="/application" component={ApplicationRoutes} />
        <Route path="/landing" component={Approved} />
        <Route path="/landing-2" component={RenewApproval} />
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
};

export default Routes;
