import React from "react";

export default function Settings({
  size = "14px",
  fill = "none",
  color = "#1E84BE",
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00004 1.73958C6.63185 1.73958 6.33337 2.03806 6.33337 2.40625C6.33337 3.53442 4.96937 4.09941 4.17163 3.30167C3.91128 3.04132 3.48917 3.04132 3.22882 3.30167C2.96847 3.56202 2.96847 3.98413 3.22882 4.24448C4.02659 5.04225 3.46153 6.40625 2.33337 6.40625C1.96518 6.40625 1.66671 6.70473 1.66671 7.07292C1.66671 7.44111 1.96518 7.73958 2.33337 7.73958C3.46161 7.73958 4.02647 9.10366 3.22878 9.90135C2.96843 10.1617 2.96843 10.5838 3.22878 10.8442C3.48913 11.1045 3.91124 11.1045 4.17159 10.8442C4.96931 10.0464 6.33337 10.6114 6.33337 11.7396C6.33337 12.1078 6.63185 12.4062 7.00004 12.4062C7.36823 12.4062 7.66671 12.1078 7.66671 11.7396C7.66671 10.6114 9.03073 10.0464 9.82848 10.8441C10.0888 11.1045 10.5109 11.1045 10.7713 10.8441C11.0316 10.5838 11.0316 10.1617 10.7713 9.90134C9.97358 9.10362 10.5385 7.73958 11.6667 7.73958C12.0349 7.73958 12.3334 7.44111 12.3334 7.07292C12.3334 6.70473 12.0349 6.40625 11.6667 6.40625C10.5386 6.40625 9.97346 5.04228 10.7713 4.24449C11.0316 3.98414 11.0316 3.56204 10.7713 3.30169C10.5109 3.04134 10.0888 3.04134 9.82844 3.30169C9.03068 4.09944 7.66671 3.53438 7.66671 2.40625C7.66671 2.03806 7.36823 1.73958 7.00004 1.73958ZM5.00548 2.2576C5.08153 1.22245 5.94547 0.40625 7.00004 0.40625C8.05461 0.40625 8.91855 1.22245 8.9946 2.2576C9.78034 1.57943 10.9684 1.61319 11.7141 2.35888C12.4598 3.10457 12.4935 4.29261 11.8153 5.07835C12.8505 5.15439 13.6667 6.01833 13.6667 7.07292C13.6667 8.12749 12.8505 8.99142 11.8154 9.06748C12.4935 9.85322 12.4598 11.0413 11.7141 11.787C10.9684 12.5327 9.78034 12.5664 8.9946 11.8882C8.91857 12.9234 8.05462 13.7396 7.00004 13.7396C5.94546 13.7396 5.08151 12.9234 5.00548 11.8882C4.21974 12.5664 3.03168 12.5327 2.28597 11.787C1.54027 11.0413 1.50652 9.85322 2.18472 9.06748C1.14957 8.99142 0.333374 8.12749 0.333374 7.07292C0.333374 6.01833 1.1496 5.15439 2.18477 5.07835C1.50656 4.29261 1.54031 3.10456 2.28601 2.35887C3.03171 1.61317 4.21974 1.57942 5.00548 2.2576Z"
        fill={color}
      />
      <path
        d="M7.00004 5.73958C6.64642 5.73958 6.30728 5.88006 6.05723 6.13011C5.80718 6.38016 5.66671 6.71929 5.66671 7.07292C5.66671 7.42654 5.80718 7.76568 6.05723 8.01573C6.30728 8.26577 6.64642 8.40625 7.00004 8.40625C7.35366 8.40625 7.6928 8.26577 7.94285 8.01573C8.1929 7.76568 8.33337 7.42654 8.33337 7.07292C8.33337 6.71929 8.1929 6.38016 7.94285 6.13011C7.6928 5.88006 7.35366 5.73958 7.00004 5.73958ZM5.11442 5.1873C5.61452 4.6872 6.2928 4.40625 7.00004 4.40625C7.70728 4.40625 8.38556 4.6872 8.88566 5.1873C9.38576 5.6874 9.66671 6.36567 9.66671 7.07292C9.66671 7.78016 9.38576 8.45844 8.88566 8.95854C8.38556 9.45863 7.70728 9.73958 7.00004 9.73958C6.2928 9.73958 5.61452 9.45863 5.11442 8.95854C4.61433 8.45844 4.33337 7.78016 4.33337 7.07292C4.33337 6.36567 4.61433 5.6874 5.11442 5.1873Z"
        fill={color}
      />
    </svg>
  );
}
